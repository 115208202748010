import * as React from 'react'
import { graphql } from 'gatsby'

import * as styles from './care-info.module.scss'

export const query = graphql`
  fragment ProductCareInfoFragment on Product {
    laundrySymbols {
      name
      icon {
        publicURL
      }
    }
    care
    caseLaundrySymbols {
      name
      icon {
        publicURL
      }
    }
    caseCare
  }
`

/** Laundry Symbols and Care Label */
const CareInfo = ({
  laundrySymbols, care
}) => (
  <div className={styles.container}>
    {laundrySymbols &&
      <ul className={styles.laundrySymbols}>
        {laundrySymbols.map(laundrySymbol => (
          <li key={`laundry-symbol:${laundrySymbol.name}`}>
            <div
              style={{
                backgroundImage: `url(${laundrySymbol.icon.publicURL})`,
              }}
              className={styles.laundrySymbol}
              role="presentation"
            >
            </div>
          </li>
        ))}
      </ul>
    }
    {care &&
      <article className={styles.careRecommendations}>
        {care.map((text, index) => (
          <p className={styles.careRecommendation} key={`care:${index}`}>
            {text}
          </p>
        ))}
      </article>
    }
  </div>
)

export default CareInfo
