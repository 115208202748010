import * as React from 'react'

import * as styles from './anchor.module.scss'

const Anchor = ({ id, children }) => (
  <a
    className={styles.anchor}
    onClick={event => event.preventDefault()}
    id={id}
    href={`#${id}`}
  >
    {children}
  </a>
)

export default Anchor
