import * as React from 'react'
import { graphql } from 'gatsby'

import * as styles from './product-composition.module.scss'

export const query = graphql`
  fragment ProductCompositionFragment on Product {
    materials
  }
`

const ProductComposition = ({
  product: {
    materials
  }
}) => (
  <div className={styles.container}>
    <ul className={styles.materials}>
      {materials.map((material, index) => (
        <li className={styles.material} key={`material:${index}`}>
          {material}
        </li>
      ))}
    </ul>
  </div>
)

export default ProductComposition
