import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'

import ThreeSixty from '@mladenilic/threesixty.js'

import { useMouseMediaQuery } from '../media-query'

import PrevIcon from '../../images/icons/previous.inline.svg'
import NextIcon from '../../images/icons/next.inline.svg'
import PlayPauseIcon from '../../images/icons/play-pause.inline.svg'

import * as styles from './viewer-360.module.scss'

const Viewer360 = ({ variant }) => {
  const viewerRef = useRef(null)
  const [threeSixty, setThreeSixty] = useState(null)

  const threeSixtyImages = variant.threeSixtyImages

  useEffect(() => {
    const images = threeSixtyImages.map(image => image.original.src)

    images.forEach(src => {
      // Preloading images to prevent blinking
      const preload = new Image()
      preload.src = src
    })

    const threeSixty = new ThreeSixty(viewerRef.current, {
      speed: 250,
      image: images,
      inverted: true
    })

    const style = threeSixty.container.style
    style.width = '100%'
    style.height = '100%'

    setThreeSixty(threeSixty)
  }, [threeSixtyImages])

  const toggle = () => {
    threeSixty.toggle()
  }

  const prev = () => {
    threeSixty.stop()
    threeSixty.prev()
  }

  const next = () => {
    threeSixty.stop()
    threeSixty.next()
  }

  const usesMouse = useMouseMediaQuery()

  return (
    <div className={styles.container}>
      <div className={styles.viewer} ref={viewerRef}></div>
      <div className={styles.controls}>
        <button
          title="Повернуть"
          onClick={prev}
        >
          <PrevIcon className={styles.icon} />
        </button>
        <button
          title="Запустить/остановить анимацию вращения"
          onClick={toggle}
        >
          <PlayPauseIcon className={styles.icon} />
        </button>

        <button
          title="Повернуть"
          onClick={next}
        >
          <NextIcon className={styles.icon} />
        </button>
      </div>
      {usesMouse &&
        <div className={styles.hint}>
          Зажмите левую кнопку мыши поверх фото и потяните в любую сторону
        </div>
      }
    </div>
  )
}

export const query = graphql`
  fragment VariantThreeSixtyFragment on Variant {
    threeSixtyImages {
      original {
        src
        width
        height
      }
    }
  }
`

export default Viewer360
