// extracted by mini-css-extract-plugin
export var breadcrumb = "product-module--breadcrumb--cEshm";
export var breadcrumbs = "product-module--breadcrumbs--e5XKL";
export var buttonThreeSixty = "product-module--button-three-sixty--Qcw0d";
export var buttonWildberries = "product-module--button-wildberries--aOXnt";
export var buyButtons = "product-module--buy-buttons--79isH";
export var care = "product-module--care--3OjHo";
export var composition = "product-module--composition--iuey1";
export var container = "product-module--container--qLFvq";
export var description = "product-module--description--uUFwe";
export var details = "product-module--details--IMl0Q";
export var header = "product-module--header--bSX1U";
export var image = "product-module--image--lCVza";
export var relatedProducts = "product-module--related-products--y1aas";
export var relatedProductsHeader = "product-module--related-products-header--81T3n";
export var sizes = "product-module--sizes--f2-sR";
export var subtitle = "product-module--subtitle--94cne";
export var threeSixty = "product-module--three-sixty--j9yAe";