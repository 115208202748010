import * as React from 'react'
import { graphql, Link } from 'gatsby'

import FixedSizeImage from '../fixed-size-image'

import * as styles from './color-selector.module.scss'

export const query = graphql`
  fragment VariantColorFragment on Variant {
    color {
      name
    }
  }

  fragment ProductColorsFragment on Product {
    colors {
      name
      title
      sampleImage {
        gatsbyImageData(
          layout: FIXED,
          width: 20,
          height: 20,
          outputPixelDensities: [1, 2, 3]
        )
      }
    }
  }
`

const ColorSelector = ({ variant, product }) => (
  <ul className={styles.palette}>
    {product.colors.map(color => {
      const targetVariant = product.variants.find(productVariant => {
        return productVariant.color.name === color.name
          && productVariant.size.name === variant.size.name
      })

      const itemClassName = variant.color.name === color.name
        ? `${styles.item} ${styles.selected}`
        : styles.item
      return (
        <li key={`color:${color.name}`} className={itemClassName}>
          <Link
            className={styles.link}
            to={`/${targetVariant.pagePath}/`}
            title={color.title}
          >
            <FixedSizeImage
              gatsbyImageData={color.sampleImage.gatsbyImageData}
              alt={color.title}
              className={styles.sampleImage}
            />
          </Link>
        </li>
      )
    })}
  </ul>
)

export default ColorSelector
