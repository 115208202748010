import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

import * as styles from './related-products-slider.module.scss'
import 'swiper/scss'
import 'swiper/scss/navigation'

const RelatedProductsSlider = ({ relatedVariants }) => (
  <Swiper
    slidesPerView={`auto`}
    modules={[
      Navigation
    ]}
    navigation={{
      prevEl: `.${styles.navigationButtonPrev}`,
      nextEl: `.${styles.navigationButtonNext}`,
    }}
    className={styles.container}
  >
    {relatedVariants.map((variant, index) => {
      const image = variant.images[0]
      const title = variant.product.title

      return (
        <SwiperSlide
          className={styles.card}
          key={`related-product:${index}`}
        >
          <Link
            className={styles.content}
            to={`/${variant.pagePath}`}
          >
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={title}
              backgroundColor={image.gatsbyImageData.backgroundColor}
            />
            <h4>{title}</h4>
          </Link>
        </SwiperSlide>
      )
    })}
  </Swiper>
)

export const query = graphql`
  fragment VariantRelatedProductsFragment on Variant {
    related {
      pagePath

      product {
        title
      }

      images {
        gatsbyImageData
      }
    }
  }
`

export default RelatedProductsSlider
