import React, { useEffect } from 'react'
import Modal from 'react-modal'

import CloseIcon from '../../images/icons/close.inline.svg'

import * as styles from './modal-view.module.scss'

const ModalView = ({ state, children }) => {
  useEffect(() => {
    Modal.setAppElement('body')
  })

  return (
    <Modal
      closeTimeoutMS={500}
      isOpen={state.visibleContent !== null}
      onRequestClose={state.close}
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
      contentLabel="Slider"
    >
      {children}
      <button className={styles.closeButton} onClick={state.close}>
        <CloseIcon className={styles.closeIcon} />
      </button>
    </Modal>
  )
}

export default ModalView
