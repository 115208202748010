import * as React from 'react'

import { useMobileMediaQuery } from '../media-query'
import StickyContent from './sticky-content'

import * as styles from './details-container.module.scss'

const DetailsContainer = ({
  contentClassName,
  children
}) => {
  const isMobile = useMobileMediaQuery()

  return isMobile ? (
    <div className={styles.mobileContainer}>
      <div className={contentClassName}>
        {children}
      </div>
    </div>
  ) : (
    <StickyContent
      containerClassName={styles.stickyContainer}
      contentClassName={contentClassName}
    >
      {children}
    </StickyContent>
  )
}

export default DetailsContainer
