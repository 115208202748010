import * as React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import Anchor from '../components/anchor'

import ImagesGallery from '../components/product-page/images-gallery'
import DetailsContainer from '../components/product-page/details-container'

import ColorSelector from '../components/product-page/color-selector'
import SizeSelector from '../components/product-page/size-selector'

import RelatedProductsSlider from '../components/product-page/related-products-slider'

import ModalView from '../components/product-page/modal-view'
import { useModalViewState } from '../components/product-page/modal-view-state'

import Slider from '../components/product-page/slider'
import Viewer360 from '../components/product-page/viewer-360'

import ProductComposition from '../components/product-page/product-composition'
import CareInfo from '../components/product-page/care-info'

import * as styles from './product.module.scss'
import * as relatedProductsStyles from '../components/product-page/related-products-slider.module.scss'

import Icon360 from '../images/icons/360.svg'
import WildberriesButton from '../components/product-page/wildberries-button'

export const query = graphql`
  fragment BreadcrumbsFragment on Product {
    category {
      title
      pagePath
    }

    section {
      title
      pagePath

      categories { 
        id
      }
    }
  }

  query ($id: String!) {
    variant(id: {eq: $id}) {
      sku

      color {
        productRespectiveForm
        title
      }

      ...VariantColorFragment
      ...VariantSizeFragment
      ...VariantRelatedProductsFragment
      ...VariantThreeSixtyFragment
      marketplaces {
        name
        vendorCode
      }
      images {
        gatsbyImageData
        original {
          height
          width
        }
      }
      sizeImages {
        gatsbyImageData
        original {
          height
          width
        }
      }
      pagePath

      product {
        name
        title
        description

        ...BreadcrumbsFragment

        ...ProductColorsFragment
        ...ProductSizesFragment

        variants {
          pagePath
          ...VariantSizeFragment
          ...VariantColorFragment
        }
        markdown: parent {
          ... on MarkdownRemark {
            html
          }
        }

        ...ProductCompositionFragment
        ...ProductCareInfoFragment
      }
    }
  }
`

const ProductPageTemplate = ({
  data: { variant },
}) => {
  const product = variant.product
  const productTitle = product.title

  const breadcrumbs = [
    product.section,
    product.section.categories.length > 1 ? product.category : null,
  ].filter(breadcrumb => breadcrumb)

  const pageTitle = [
    productTitle,
    ...breadcrumbs.map(({ title }) => title).reverse()
  ].join(` | `)

  const modalViewState = useModalViewState()

  const wildberriesProductPage = variant.marketplaces.find(({ name }) =>
    name === `wildberries`
  )

  const variantImages = variant.sizeImages.concat(variant.images)
  const subtitle = variant.color.productRespectiveForm || variant.color.title

  return (
    <Layout
      pageTitle={pageTitle}
      pageDescription={product.description}
      headerOverContent={true}
    >
      <article className={styles.container}>
        <ImagesGallery>
          {variantImages.map((image, imageIndex) => {
            const onClick = () => modalViewState.openSlider(imageIndex)

            return (
              <div
                key={`product-image:${imageIndex}`}
                role="button"
                onClick={onClick}
                onKeyPress={onClick}
                tabIndex={0}
              >
                <GatsbyImage
                  className={styles.image}
                  image={image.gatsbyImageData}
                  alt={productTitle}
                />
              </div>
            )
          })}
        </ImagesGallery>

        <DetailsContainer contentClassName={styles.details}>
          <nav className={styles.breadcrumbs}>
            {breadcrumbs.map(({ title, pagePath }, index) => (
              <Link
                key={`breadcrumb:${index}`}
                className={styles.breadcrumb}
                to={`/${pagePath}`}
              >
                {title}
              </Link>
            ))}
          </nav>
          <article>
            <header className={styles.header}>
              <h2>
                {productTitle}
              </h2>
              {subtitle &&
                <h3 className={styles.subtitle}>
                  {subtitle}
                </h3>
              }
            </header>

            {variant.threeSixtyImages.length !== 0 &&
              <section className={styles.threeSixty}>
                <button
                  className={styles.buttonThreeSixty}
                  onClick={modalViewState.open360Viewer}
                >
                  <img src={Icon360} alt="Вид 360" />
                </button>
              </section>
            }

            <section>
              <ColorSelector variant={variant} product={product} />
            </section>

            <section
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: product.markdown.html }}
            />
            <section className={styles.sizes} >
              <h3>
                <Anchor id="sizes">
                  Размеры
                </Anchor>
              </h3>
              <SizeSelector variant={variant} product={product} />
            </section>

            <section className={styles.buyButtons}>
              {wildberriesProductPage && <WildberriesButton variant={variant} className={styles.buttonWildberries} vendorCode={wildberriesProductPage.vendorCode} />}
            </section>
          </article>
          {variant.related &&
            <aside className={styles.relatedProducts}>
              <header className={styles.relatedProductsHeader} >
                <h3>
                  <Anchor id="related">
                    Сочетающиеся товары
                  </Anchor>
                </h3>
                <div className={relatedProductsStyles.navigationButtons}>
                  <button className={relatedProductsStyles.navigationButtonPrev} aria-label="Предыдущий товар" />
                  <button className={relatedProductsStyles.navigationButtonNext} aria-label="Следующий товар" />
                </div>
              </header>

              <RelatedProductsSlider relatedVariants={variant.related} />
            </aside>
          }
          {(product.care || product.laundrySymbols) &&
            <section className={styles.care}>
              <h3>
                <Anchor id="care">
                  Уход
                </Anchor>
              </h3>
              <CareInfo laundrySymbols={product.laundrySymbols} care={product.care}/>
              {(product.caseCare || product.caseLaundrySymbols) && 
                <div>
                  <h3>
                    <Anchor id="caseCare">
                      Уход за съёмным чехлом
                    </Anchor>
                  </h3>
                  <CareInfo laundrySymbols={product.caseLaundrySymbols} care={product.caseCare}/>
                </div>
              }
            </section>
          }
          {product.materials &&
            <section className={styles.composition}>
              <h3>
                <Anchor id="composition">
                  Состав
                </Anchor>
              </h3>
              <ProductComposition product={product} />
            </section>
          }
        </DetailsContainer>
      </article>

      <ModalView state={modalViewState}>
        {modalViewState.visibleContent === `slider` &&
          <Slider
            initialSlide={modalViewState.initialSlide}
            variant={variant}
            sharedImagesTitle={productTitle}
          />
        }
        {modalViewState.visibleContent === `360-viewer` &&
          <Viewer360 variant={variant} />
        }
      </ModalView>
    </Layout>
  )
}

export default ProductPageTemplate
