import * as React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'

import { useMobileMediaQuery } from '../media-query'

import * as styles from './images-gallery.module.scss'
import 'swiper/scss'
import 'swiper/scss/pagination'

const ImagesGallery = ({ className, children }) => {
  const isMobile = useMobileMediaQuery()

  return isMobile ? (
    <Swiper
      modules={[
        Pagination,
      ]}
      pagination={{
        dynamicBullets: true,
      }}
      className={styles.slider}
    >
      {children.map((child, index) => (
        <SwiperSlide key={`slide-image:${index}`}>
          {child}
        </SwiperSlide>
      ))}
    </Swiper>
  ) : (
    <section className={styles.flow}>
      {children}
    </section >
  )
}

export default ImagesGallery
