import React, { useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Zoom, Navigation } from 'swiper'

import ZoomInIcon from '../../images/icons/zoom-in.inline.svg'
import ZoomOutIcon from '../../images/icons/zoom-out.inline.svg'

import * as styles from './slider.module.scss'
import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/zoom'

const Slider = ({
  initialSlide,
  variant,
  sharedImagesTitle
}) => {
  const [swiper, setSwiper] = useState(null)
  const [isZoomedIn, setZoomedIn] = useState(false)


  const zoomIn = () => {
    if (!isZoomedIn) swiper.zoom.in()
  }

  const zoomOut = () => {
    if (isZoomedIn) swiper.zoom.out()
  }

  const toggleZoom = () => {
    swiper.zoom.toggle()
  }

  return (
    <div className={styles.container}>
      <Swiper
        initialSlide={initialSlide}
        loop={true}
        modules={[
          Zoom,
          Navigation
        ]}
        zoom={{
          toggle: false,
          zoomedSlideClass: styles.sliderZoomedIn
        }}
        onZoomChange={(_swiper, scale, _imageEl, _slideEl) => {
          setZoomedIn(scale > 1)
        }}
        navigation={true}
        className={styles.slider}
        onSwiper={swiper => {
          setSwiper(swiper)
          setZoomedIn(false)
        }}
      >
        {variant.images.map((image, index) => {
          const { width, height } = image.original
          const aspectRatio = width / height

          // Required for correct height of Gatsby Image
          const imageStyle = {
            maxHeight: `100vh`,
            width: `calc(100vh * ${aspectRatio})`,
          }

          const alt = sharedImagesTitle

          return (
            <SwiperSlide key={`slider-image:${index}`}>
              <div
                className='swiper-zoom-container'
                role="button"
                onClick={zoomIn}
                onKeyPress={toggleZoom}
                onDoubleClick={zoomOut}
                tabIndex={0}
              >
                <GatsbyImage
                  image={image.gatsbyImageData}
                  alt={alt}
                  className={`swiper-zoom-target ${styles.slideImage}`}
                  style={imageStyle} 
                  backgroundColor={image.gatsbyImageData.backgroundColor}
                />
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>

      <div className={styles.zoomButtons}>
        <button
          className={isZoomedIn ? styles.zoomButtonActive : styles.zoomButtonInactive}
          onClick={isZoomedIn ? zoomOut : null}
        >
          <ZoomOutIcon className={styles.zoomIcon} />
        </button>

        <button
          className={isZoomedIn ? styles.zoomButtonInactive : styles.zoomButtonActive}
          onClick={isZoomedIn ? null : zoomIn}
        >
          <ZoomInIcon className={styles.zoomIcon} />
        </button>
      </div>
    </div>
  )
}

export default Slider
