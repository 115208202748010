import * as React from 'react'
import { graphql, Link } from 'gatsby'
import * as styles from './size-selector.module.scss'

const SizeSelector = ({ variant, product }) => {
  return (
    <ul className={styles.list}>
      {product.sizes.map(size => {
        const targetVariant = product.variants.find(productVariant => {
          return productVariant.size.name === size.name
            && productVariant.color.name === variant.color.name
        })

        const isSelected = variant.size.name === size.name
        const itemClassName = isSelected ? `${styles.item} ${styles.selected}` : styles.item

        return (
          <li key={`size:${size.name}`} className={itemClassName}>
            <Link to={`/${targetVariant.pagePath}/`} className={styles.link}>
              {size.name}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export const query = graphql`
  fragment VariantSizeFragment on Variant {
    size {
      name
    }
  }
  fragment ProductSizesFragment on Product {
    sizes {
      name
    }
  }
`

export default SizeSelector
