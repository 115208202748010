import * as React from 'react'

/** Convert viewport-based selection to device-pixel-ratio-based (by pixel density) */
const srcSetByDensity = (srcSet, width) => {
  const resourcesSeparator = `,\n`
  const resourceAttributesSeparator = ` `

  return srcSet
    .split(resourcesSeparator)
    .map(resource => {
      const [path, descriptor] = resource.split(resourceAttributesSeparator)

      const descriptorValue = Number(descriptor.replace(`w`, ``))
      const densityDescriptor = `${descriptorValue / width}x`

      return [path, densityDescriptor].join(resourceAttributesSeparator)
    })
    .join(resourcesSeparator)
}

const FixedSizeImage = ({
  gatsbyImageData: {
    images,
    width
  },
  alt,
  className,
}) => {
  const { fallback, sources } = images

  return (
    <picture>
      {sources.map((source, index) => (
        <source
          key={`source:${index}`}
          srcSet={srcSetByDensity(source.srcSet, width)}
          type={source.type}
        />
      ))}
      <img
        loading="lazy"
        className={className}
        src={fallback.src}
        srcSet={srcSetByDensity(fallback.srcSet, width)}
        alt={alt}
      />
    </picture>
  )
}

export default FixedSizeImage
