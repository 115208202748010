import React, { useEffect, useRef } from 'react'

const StickyContent = ({
  containerClassName,
  contentClassName,
  children
}) => {
  const containerRef = useRef(null)
  const contentRef = useRef(null)

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current
      const content = contentRef.current

      const contentHeight = content.clientHeight
      const containerRect = container.getBoundingClientRect()
      const contentBottom = contentHeight + containerRect.top

      if (containerRect.bottom <= window.innerHeight) {
        // Aligned to bottom of container
        container.style.justifyContent = `flex-end`
        container.style.minHeight = null

        content.style.position = `static`
        content.style.bottom = null
      } else if (contentBottom <= window.innerHeight) {
        // Fixed to bottom of window
        container.style.justifyContent = null
        container.style.minHeight = `${contentHeight}px`

        content.style.position = `fixed`
        content.style.bottom = 0
      } else {
        // Aligned to top of container
        container.style.justifyContent = `flex-start`
        container.style.minHeight = null

        content.style.position = `static`
        content.style.bottom = null
      }
    }

    handleScroll()
    window.addEventListener(`scroll`, handleScroll)

    return () => window.removeEventListener(`scroll`, handleScroll)
  }, [])

  return (
    <div ref={containerRef} className={containerClassName}>
      <div ref={contentRef} className={contentClassName}>
        {children}
      </div>
    </div>
  )
}

export default StickyContent
