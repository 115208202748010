import { useState, useEffect } from 'react'

export const useModalViewState = () => {
  const [visibleContent, setVisibleContent] = useState(null)
  const [initialSlide, setInitialSlide] = useState(0)

  const open360Viewer = () => {
    setVisibleContent(`360-viewer`)
  }

  const openSlider = (slideNumber) => {
    setInitialSlide(slideNumber)
    setVisibleContent(`slider`)
  }

  const close = () => {
    setVisibleContent(null)
    setInitialSlide(0)
  }

  useEffect(() => {
    // Prevent scrolling of page content when modal is open
    document.body.style.overflow = visibleContent ? 'hidden' : 'unset'
  })

  return {
    visibleContent,
    initialSlide,
    openSlider,
    open360Viewer,
    close,
  }
}
