import React from 'react'
import { navigate } from 'gatsby'

const WildberriesButton = ({
  className,
  variant,
  vendorCode
}) => {

  const goToWildberries = () => {
    window.VK.Retargeting.Event(`WBClick`)

    if (process.env.NODE_ENV === `production`) {
      const product = variant.product
      const obj = {
        URL: document.location.href,
        title: product.title,
        name: product.name,
        color: variant.color.name,
        size: variant.size.name,
        vendorCode: vendorCode
      }
      
      window.ym(89533692, `reachGoal`, `WBClick`, obj)
    }

    navigate(`https://www.wildberries.ru/catalog/${vendorCode}/detail.aspx?targetUrl=XS`)
  }

  return (
    <button onClick={goToWildberries} className={className}>Купить на Wildberries</button>
  )
}

export default WildberriesButton
